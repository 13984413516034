import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';

const VALIDATION_SERVER_URL = process.env.REACT_APP_VALIDATION_SERVER_URL;
const VALIDATION_SERVER_API_KEY =
  process.env.REACT_APP_VALIDATION_SERVER_API_KEY;

export const sendEmail = async (sender, content, fnSnackbar) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Api-Key ${VALIDATION_SERVER_API_KEY}`,
  };

  const { subject, message } = content;

  try {
    await axios.post(
      `${VALIDATION_SERVER_URL}/send_email/`,
      {
        sender_email: sender,
        subject,
        message,
      },
      {
        headers,
      },
    );

    fnSnackbar('Your request email has been sent successfully.', {
      variant: 'success',
      ...snackDefaultConfig,
    });

    return true;
  } catch (err) {
    console.error(err);

    fnSnackbar('Failed to send your request email.', {
      variant: 'error',
      ...snackDefaultConfig,
    });
  }

  return false;
};

export const checkPrivateEmail = (value) => {
  const someDomains = [
    'gmail.com',
    'outlook.com',
    'hotmail.com',
    'yahoo.com',
    'yandex.com',
  ];
  try {
    const domain = value.split('@')[1];

    return !someDomains.includes(domain);
  } catch (err) {
    return false;
  }
};

export const snackDefaultConfig = {
  autoHideDuration: 3000,
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
};

export const gridCol = (option) => {
  let colspanCls = 'col-span-12';
  colspanCls += option.tablet ? ' sm:col-span-' + 12 / option.tablet : '';
  colspanCls += option.laptop ? ' lg:col-span-' + 12 / option.laptop : '';
  colspanCls += option.desktop ? ' xl:col-span-' + 12 / option.desktop : '';

  return colspanCls;
};

export const fadeIn = (element, duration) => {
  let opacity = 0;
  const interval = 20; // Adjust as needed for smoothness

  const timer = setInterval(() => {
    if (opacity >= 1) {
      clearInterval(timer);
    }

    element.style.opacity = opacity;
    opacity += interval / duration;
  }, interval);
};

export const fadeOut = (element, duration) => {
  let opacity = 1;
  const interval = 20; // Adjust as needed for smoothness

  const timer = setInterval(() => {
    if (opacity <= 0) {
      clearInterval(timer);
    }

    element.style.opacity = opacity;
    opacity -= interval / duration;
  }, interval);
};

export const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace('/' + param, ''),
    location.pathname,
  );
};

export const formatNumber = (num) => {
  if (Math.abs(num) < 1024) {
    return num.toString(); // Numbers less than 1000 are displayed as is
  } else if (Math.abs(num) >= 1024 && Math.abs(num) < 1024 * 1024) {
    return (num / 1024).toFixed(2) + ' kB'; // Display numbers in thousands
  } else if (Math.abs(num) >= 1024 * 1024 && Math.abs(num) < 1024 * 1024 * 1024) {
    return (num / 1024 / 1024).toFixed(2) + ' MB'; // Display numbers in millions
  } else if (Math.abs(num) >= 1024 * 1024 * 1024 && Math.abs(num) < 1024 * 1024 * 1024 * 1024) {
    return (num / 1024 / 1024 / 1024).toFixed(2) + ' GB'; // Display numbers in billions
  } else {
    return (num / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'; // Display numbers in trillions
  }
}
