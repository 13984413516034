import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { router } from 'routes';

import { initializeGA } from 'utils/analytics';

function App() {
  useEffect(() => {
    initializeGA(); // Initialize GA when the app starts
  }, []);

  return (
    <SnackbarProvider>
      <RouterProvider router={router} />
    </SnackbarProvider>
  );
}

export default App;
