// src/routes/index.js
import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Layout from 'components/layout';
import BasicLayout from 'components/layout/BasicLayout';
import LoadingSpinner from 'components/common/LoadingSpinner';

const Home = lazy(() => import('pages/home'));
const Solution = lazy(() => import('pages/solution'));
const AttackSurfaceDiscover = lazy(() =>
  import('pages/solution/AttackSurfaceDiscover'),
);
const DomainSpoofingDetection = lazy(() =>
  import('pages/solution/DomainSpoofingDetection'),
);
const DataBreachDetection = lazy(() =>
  import('pages/solution/DataBreachDetection'),
);
const DarknetMonitoringMSSP = lazy(() =>
  import('pages/solution/DarknetMonitoringMSSP'),
);
const Knowledge = lazy(() => import('pages/Knowledge'));
const NewsDetail = lazy(() => import('pages/NewsDetail'));
const Pricing = lazy(() => import('pages/Pricing'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const Calendly = lazy(() => import('pages/Calendly'));

const PrivacyPolicy = lazy(() => import('pages/etc/PrivacyPolicy'));
const Imprint = lazy(() => import('pages/etc/Imprint'));
const ToS = lazy(() => import('pages/etc/ToS'));

const SpainPage = lazy(() => import('pages/countries/Spain'));
const FrancePage = lazy(() => import('pages/countries/France'));

const LogIn = lazy(() => import('pages/Login'));

const NotFoundPage = lazy(() => import('pages/common/NotFoundPage'));
const ServerErrorPage = lazy(() => import('pages/common/ServerErrorPage'));

// Define routes
export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<LoadingSpinner />}>
        <Layout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'solution/',
        children: [
          {
            index: true,
            element: <Solution />,
          },
          {
            path: 'attack-surface-discover',
            element: <AttackSurfaceDiscover />,
          },
          {
            path: 'domain-spoofing-detection',
            element: <DomainSpoofingDetection />,
          },
          {
            path: 'data-breach-detection',
            element: <DataBreachDetection />,
          },
          {
            path: 'darknet-monitoring-mssp',
            element: <DarknetMonitoringMSSP />,
          },
        ],
      },
      {
        path: 'knowledge/',
        children: [
          {
            index: true,
            element: <Knowledge />,
          },
          {
            path: 'news/:newsId',
            element: <NewsDetail />,
          },
        ],
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: 'about-us',
        element: <AboutUs />,
      },
      {
        path: 'calendly',
        element: <Calendly />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'imprint',
        element: <Imprint />,
      },
      {
        path: 'tos',
        element: <ToS />,
      },
      {
        path: 'spain',
        element: <SpainPage />,
      },
      {
        path: 'france',
        element: <FrancePage />,
      },
    ],
  },
  {
    path: 'login',
    element: (
      <Suspense fallback={<LoadingSpinner />}>
        <LogIn />
      </Suspense>
    ),
  },
  {
    path: '500',
    element: (
      <Suspense fallback={<LoadingSpinner />}>
        <BasicLayout>
          <ServerErrorPage />
        </BasicLayout>
      </Suspense>
    ),
  },
  {
    path: '*',
    element: <Navigate to="/knowledge" replace />,
    // element: (
    //   <Suspense fallback={<LoadingSpinner />}>
    //     <BasicLayout>
    //       <NotFoundPage />
    //     </BasicLayout>
    //   </Suspense>
    // ),
  },
]);
